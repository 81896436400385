



















import { Component } from 'vue-property-decorator';
import CategorySelectorComponent from '@/components/CategorySelectorComponent.vue';
import CompanyCmsProfileBaseWidget from '@/components/company-cms/CompanyCmsProfileBaseWidget.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import { namespace } from 'vuex-class';
import Category from '@/models/graphql/Category';
import EntityType from '@/utils/enums/EntityType';

const permissionManagerStore = namespace('PermissionManagerStore');

@Component({
  components: {
    CategorySelectorComponent,
  },
})
export default class CompanyCmsProfileCategoryWidget extends mixins(CompanyCmsProfileBaseWidget, VueRegisterStoreWidget) {
  @permissionManagerStore.Getter
  private canManageCompanyContent!: (companyUid: string) => boolean;

  private categoryDomain: string[] = [];

  private title = '';

  private get canManageCompanyInformation(): boolean {
    if (this.adminPanelExhibitor) {
      return this.canManageCompanyContent(this.adminPanelExhibitor.uid);
    }
    return false;
  }

  created(): void {
    this.setDataConfig();
    if (this.widget && this.payload && 'categoryDomain' in this.payload && this.payload.categoryDomain) {
      this.categoryDomain = (this.payload.categoryDomain as string).split(',');
    }
    if (this.widget && this.payload && 'title' in this.payload) {
      this.title = (this.payload.title as string);
    }
  }

  private removeCategoriesFromExhibitor({
    uid,
    entityType,
  }: { uid: string; entityType: string }): void {
    if (this.adminPanelExhibitor && entityType === EntityType.EXHIBITOR) {
      this.removeExhibitorCategory(uid);
    }
  }

  private addCategoriesToExhibitor({
    category,
    entityType,
  }: { category: Category; entityType: string }): void {
    if (this.adminPanelExhibitor && entityType === EntityType.EXHIBITOR) {
      this.addExhibitorCategory(category);
    }
  }
}
